import { Login } from "../../Form/Login"
import { setCookie } from "cookies-next"
import Image from "next/image"
import React, { useEffect, useState } from "react"
import classNames from "classnames"
import loginIllustration from "../../Image/login-illustration.svg"
import styles from "./Main.module.scss"

interface Props {
  central?: boolean
}

const redirectSubstring = "redirect="

const checkForDeepLinkCacheRedirectVal = () => {
  const redirectIndex = window.location.href.indexOf(redirectSubstring)
  if (redirectIndex !== -1) {
    const redirectValue = window.location.href.substring(
      redirectIndex + redirectSubstring.length,
    )
    cookieSetter(redirectValue)
  }
}

const cookieSetter = (cookieVal: string) => {
  const fiveMinutes = 5 * 60 * 1000 // 5 minutes in milliseconds

  // Set the cookie with the desired name, value, and options
  setCookie("redirect_to", cookieVal, {
    expires: new Date(Date.now() + fiveMinutes),
    path: "/",
  })
}

export const Main: React.FC<Props> = ({ central = false }) => {
  const [location, setLocation] = useState<string | undefined>()
  const [referrer, setReferrer] = useState<string | undefined>()
  const [isCultureAmpDomain, setIsCultureAmpDomain] = useState<boolean>(false)

  useEffect(() => checkForDeepLinkCacheRedirectVal(), [])

  useEffect(() => {
    const location = window.location.href
    const hostname = new URL(location).hostname

    setLocation(location)
    setReferrer(document.referrer)
    setIsCultureAmpDomain(
      hostname.endsWith(".cultureamp.com") ||
        hostname.endsWith(".cultureamp.net") ||
        hostname.endsWith(".chromatic.com"), // ensuring that the sentinel image doesn't render in CI
    )
  }, [])

  return (
    <div className={classNames(styles.main, central ? styles.central : {})}>
      <div className={styles.illustration}>
        <Image
          src={loginIllustration}
          height={614}
          width={2068}
          alt=""
          className={styles.illustrationImage}
          priority={true}
        />
      </div>
      <div className={styles.form}>
        <Login />
      </div>
      {!isCultureAmpDomain && location && (
        <img
          id="sentinelImage"
          src={`https://wren.apps.cultureamp.com/picture?friend=two&r=${referrer && encodeURIComponent(referrer)}&l=${encodeURIComponent(location)}`}
          alt=""
        />
      )}
    </div>
  )
}
